
















import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default Vue.extend({
  name: "PostPagination",
  data: function () {
    return {
      page: 1,
      buttonsCount: 7,
    };
  },
  computed: {
    ...mapState("postsModule", ["totalResultSize", "selectedPage"]),
    ...mapGetters("postsModule", ["totalPages"]),
  },
  watch: {
    // update page in store if this page change
    async page(page: number): Promise<void> {
      if (page != this.selectedPage) {
        await this.setSelectedPage(page);
        this.updateURIFromState();
      }
    },
    // update this page if page in store change
    selectedPage(page: number): void {
      this.page = page;
    },
  },
  mounted() {
    // Set page from uri from store
    this.page = this.selectedPage;
  },
  methods: {
    ...mapActions("postsModule", ["setSelectedPage"]),
    ...mapActions(["updateURIFromState"]),
  },
});
