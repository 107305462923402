






















































import Vue from "vue";
import Post from "@/models/post";

// Map components
import L, { Icon, IconOptions, LatLngTuple } from "leaflet";
import { LMap, LTileLayer, LTooltip } from "vue2-leaflet";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster/Vue2LeafletMarkercluster.vue";
import * as Vue2Leaflet from "vue2-leaflet";
import { GestureHandling } from "leaflet-gesture-handling";

L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

/**
 * Emits @openPost if single post is clicked
 */
export default Vue.extend({
  name: "MapCard",
  components: {
    LMap,
    LTileLayer,
    LTooltip,
    Lmarker: Vue2Leaflet.LMarker,
    LMarckerCluster: Vue2LeafletMarkerCluster,
  },
  props: {
    posts: {
      type: Array as () => Post[],
      required: true,
    },
    selectedPost: {
      type: Object as () => Post,
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      map: {
        url: "https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png",
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        center: [51.5, 10.5], // geographic center of germany
        zoom: 12,
        width: "100%",
        height: "100%",
        markerBlue: L.icon({
          iconUrl: require("@/assets/images/marker/marker-icon.png"),
          iconSize: [25, 41],
        }),
        markerRed: L.icon({
          iconUrl: require("@/assets/images/marker/marker-icon-red.png"),
          iconSize: [25, 41],
        }),
      },
      selectedMarkerMobile: undefined as Post | undefined,
      isSmartphone: false,
    };
  },
  computed: {
    /**
     * filter only posts that have a gea_location
     */
    postWithGeoLocation(): any {
      return this.posts.filter((post) => post.geo_location !== null);
    },
    useGestureHandling(): boolean {
      if (this.isMobile()) {
        return true;
      }
      return false;
    },
  },
  watch: {
    selectedPost() {
      this.rerenderMap();
    },
    posts() {
      this.rerenderMap();
    },
    show() {
      this.rerenderMap();
    },
  },
  mounted(): void {
    this.rerenderMap();
    if (window.matchMedia("(max-width: 959px)").matches) {
      this.isSmartphone = true;
    }
  },
  methods: {
    rerenderMap(): void {
      if (this.show) {
        this.$nextTick(() => {
          (this.$refs.map as LMap).mapObject.invalidateSize();
          this.$nextTick(() => {
            this.setMapLocation();
          });
        });
      }
    },
    /**
     * Sets the center of the map to the selected post.
     */
    setMapLocation(): void {
      if (this.selectedPost) {
        const location = [
          this.selectedPost.geo_location.lat,
          this.selectedPost.geo_location.lon,
        ] as LatLngTuple;
        (this.$refs.map as LMap).mapObject.panTo(location);
      } else {
        this.fitMapBounds();
      }
    },
    /**
     * Sets the viewpost of the map to see all marks.
     */
    fitMapBounds(): void {
      if (this.posts.length) {
        const markers = this.posts
          .filter((post) => post.geo_location !== null)
          .map(
            (post) =>
              [post.geo_location.lat, post.geo_location.lon] as LatLngTuple
          );
        (this.$refs.map as LMap).fitBounds(markers);
      }
    },
    /**
     * Retrieve the icon of a marker. If the post is selected the marker should be red. Otherwise it should be blue.
     *
     * @param {Post} post: Post whose marker is to be determined.
     * @return {Icon<IconOptions>}: The marker icon.
     */
    getMarker(post: Post): Icon<IconOptions> {
      // get marker for selectedPost
      if (this.selectedPost && post.id === this.selectedPost.id)
        return this.map.markerRed;
      // else get blue marker
      else return this.map.markerBlue;
    },
    openPost(post: Post): void {
      this.$emit("openPost", post);
    },
    onMarkerClick(post: Post): void {
      if (this.isSmartphone) {
        this.selectedMarkerMobile = post;
        return;
      }
      this.openPost(post);
    },
    onToolTipClick(post: Post): void {
      this.selectedMarkerMobile = undefined;
      this.openPost(post);
    },
    isMobile(): boolean {
      if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
        return true;
      }
      return false;
    },
  },
});
