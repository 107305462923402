var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"post mb-3"},[_c('v-list-item',{staticClass:"post-list-item",class:{ active: _vm.active },attrs:{"ripple":false,"three-line":""},on:{"click":_vm.onClick}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"headline mb-1",class:{ 'full-text': _vm.active },domProps:{"innerHTML":_vm._s(_vm.post.title)}}),_c('v-list-item-subtitle',{class:{
          'post-subtitle-hidden': _vm.active,
          'post-subtitle': !_vm.active,
        },attrs:{"set":(_vm.distance = _vm.postDistance(_vm.post))}},[_c('strong',[(_vm.post.post_struct.location.country === 'Deutschland')?_c('span',[_vm._v(" "+_vm._s(_vm.post.post_struct.location.zipcode)+" ")]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.post.post_struct.location.city)+" ")]),(_vm.post.post_struct.location.country !== 'Deutschland')?_c('span',[_vm._v(" "+_vm._s(_vm.post.post_struct.location.country)+" ")]):_vm._e(),(
              _vm.distance && _vm.post.post_struct.location.country === 'Deutschland'
            )?_c('em',[_vm._v(" (in "+_vm._s(_vm.distance)+") ")]):_vm._e()]),_vm._v(" — "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.post.task)}})])],1)],1),(_vm.showDetail)?_c('v-card',{staticClass:"details-smartphone",class:{
      'details-smartphone-visible': _vm.active,
      'details-smartphone-hidden': !_vm.active,
    }},[_c('v-card-text',[_vm._l((_vm.columns),function(column){return [(_vm.post[column.key])?_c('div',{key:column.key},[_c('span',[_vm._v(_vm._s(column.label))]),(column.link)?_c('span',[_c('a',{attrs:{"href":_vm.post[column.link],"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.post[column.key])+" ")])]):_c('span',{domProps:{"innerHTML":_vm._s(_vm.post[column.key])}})]):_vm._e()]})],2),_c('v-card-actions',{staticClass:"actions"},[_c('div',{staticClass:"tags"},[_vm._l((_vm.post.categories),function(category,index){return [_c('v-chip',{key:index,staticClass:"mr-2 mt-2"},[_vm._v(_vm._s(category))])]})],2),_c('v-spacer'),_c('div',{staticClass:"my-2 mt-6"},[_c('v-btn',{attrs:{"dark":"","large":"","color":"#054C66","href":_vm.post.link,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("postListItem.toOffer"))+" ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }