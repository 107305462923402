





















































































import Vue from "vue";
import Header from "@/components/layout/SearchHeader.vue";
import PostCard from "@/components/posts/PostCard.vue";
import MapCard from "@/components/posts/MapCard.vue";
import PostListItem from "@/components/posts/PostListItem.vue";
import PostListItemSkeleton from "@/components/posts/PostListItemSkeleton.vue";
import PostPagination from "@/components/posts/PostPagination.vue";
import MapButton from "@/components/posts/MapButton.vue";

import Post from "@/models/post";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default Vue.extend({
  name: "PostsView",
  components: {
    Header,
    MapButton,
    PostPagination,
    PostCard,
    MapCard,
    PostListItem,
    PostListItemSkeleton,
  },
  metaInfo: {
    title: "Ergebnisse - Einander Helfen",
  },
  data: function () {
    return {
      showMap: true,
      isSmartphone: false,
      isLoading: true,
      isInitialised: false,
      headerSpace: 130,
    };
  },
  computed: {
    ...mapState("postsModule", ["selectedPostId", "posts", "resultsFrom"]),
    ...mapGetters("postsModule", ["postsOnCurrentPage", "selectedPost"]),
    ...mapState(["radiusExtended", "radiusExtendedFrom"]),
    ...mapState("searchModule", [
      "selectedLocation",
      "selectedRadius",
      "searchValues",
      "isInternational",
    ]),
    // calc container style for mobile
    containerStyle(): any {
      return this.isSmartphone
        ? { "padding-top": this.headerSpace + "px" }
        : {};
    },
  },
  watch: {
    searchValues() {
      // recalc header space after search tags change
      this.$nextTick(() => this.calcHeaderSpace());
    },
  },
  async mounted(): Promise<void> {
    // get params from route
    await this.hydrateStateFromRoute();

    this.isInitialised = true;
    this.isLoading = true;
    // load posts by updated state parameter
    await this.loadPosts();
    this.togglePostDetails(this.selectedPost);
    this.isLoading = false;

    // set properties

    // check if device is isSmartphone view
    if (window.matchMedia("(max-width: 959px)").matches) {
      this.showMap = false;
      this.isSmartphone = true;
    }
    // close map if a post is open
    if (this.selectedPost) {
      this.showMap = false;
    }
    // calc the header height to move the container down
    this.calcHeaderSpace();
    // set resize event handler
    window.addEventListener("resize", this.onWindowResize);

    // add watcher to fire load event if search parameter have changed
    this.$watch(
      // watch all parameters which are used to find posts
      () => (
        this.searchValues,
        this.isInternational,
        this.selectedLocation,
        this.selectedRadius,
        // and to be sure that a different value is returned every time
        Date.now()
      ),
      () => {
        this.isLoading = true;
        // clear selected post
        this.togglePostDetails();
        // clear total result
        this.resetTotalResults();
        // and execute loadPosts
        this.loadPosts().finally(() => (this.isLoading = false));
      }
    );

    // results from is changes if the page is changed a new junk must be laoded
    this.$watch(
      () => (this.resultsFrom, Date.now()),
      () => {
        this.isLoading = true;
        // clear selected post
        this.togglePostDetails();
        // and execute loadPosts
        this.loadPosts().finally(() => (this.isLoading = false));
      }
    );
  },
  beforeDestroy(): void {
    // remove event handler
    window.removeEventListener("resize", this.onWindowResize);
  },
  methods: {
    ...mapMutations("searchModule", ["setSelectedRadius"]),
    ...mapMutations("postsModule", ["resetTotalResults"]),
    ...mapActions("postsModule", ["setSelectedPostId"]),
    ...mapActions([
      "hydrateStateFromRoute",
      "updateURIFromState",
      "loadPosts",
      "loadPost",
    ]),
    /** Opens a post if a post is given, else clear the selected post
     *
     * @param {Post} post: The currently selected post. Undefined if no post is selected.
     */
    async togglePostDetails(post: Post | undefined = undefined): Promise<void> {
      // close map to show detail page if not isSmartphone
      if (post && !this.isSmartphone) this.showMap = false;
      // show map if not isSmartphone
      if (!post && !this.isSmartphone) this.showMap = true;
      // set selected post id or set undefined in store
      const id = post ? post.id : undefined;
      await this.setSelectedPostId(id);
      // update uri
      await this.updateURIFromState();

      const item = document.getElementById(id ? id : "");
      const header = document.getElementById("main_header");

      // scroll to the postItem, so the item is below our header
      if (this.isSmartphone && item && header) {
        const offset = header.scrollHeight;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = item.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      } else if (item) {
        item.scrollIntoView({ behavior: "smooth" });
      }
    },
    /** Show the map  */
    openMap(): void {
      this.showMap = true;
    },
    /**
     * To move the next element after the header down,
     * calc the header height to move the content area about this value
     */
    calcHeaderSpace(): void {
      // get header element
      const searchCol = document.getElementsByClassName(
        "searchCol"
      )[0] as HTMLElement;
      if (searchCol) {
        // calc container padding
        const height = searchCol.offsetHeight;
        this.headerSpace = (height || 60) + 60;
      }
    },
    /** Resize handler that changes properties based on the window size.*/
    onWindowResize(): void {
      // swtich to isSmartphone view
      if (!this.isSmartphone && window.innerWidth < 960) {
        this.isSmartphone = true;
        this.showMap = false;
      }
      // switch to desktop view
      else if (this.isSmartphone && window.innerWidth >= 960) {
        this.isSmartphone = false;
        if (!this.selectedPost) {
          this.showMap = true;
        }
      }
    },
  },
});
