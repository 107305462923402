




















































































import Vue from "vue";
import Post from "@/models/post";
import Location from "@/models/location";

export default Vue.extend({
  name: "PostListItem",
  props: {
    post: {
      type: Object as () => Post,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    showDetail: {
      type: Boolean,
      default: false,
    },
    location: {
      type: Object as () => Location,
      default: undefined,
    },
  },
  data: function () {
    return {
      columns: [
        { key: "location", label: "Einsatzort" },
        { key: "task", label: "Aufgabe" },
        { key: "contact", label: "Ansprechpartner" },
        { key: "organization", label: "Organisation" },
        { key: "target_group", label: "Zielgruppe" },
        { key: "timing", label: "Einstiegsdatum / Beginn" },
        { key: "effort", label: "Zeitaufwand" },
        { key: "opportunities", label: "Möglichkeiten" },
        { key: "prerequisites", label: "Anforderungen" },
        { key: "language_skills", label: "Sprachen" },
        { key: "source", label: "Quelle", link: "link" },
      ],
    };
  },
  methods: {
    onClick() {
      this.$emit("click", this.post);
    },
    /**
     * Calculates the haversine distance of the post to the search location.
     *
     * @return {string}: A string containing the rounded distance + km or an empty string if no search location
     * or no geo location of the post ist given.
     */
    postDistance(post: Post): string {
      if (!this.location || !post.geo_location) {
        return "";
      }

      const distance = this.haversineDistance(
        [post.geo_location.lat, post.geo_location.lon],
        [this.location.lat, this.location.lon]
      );

      if (distance) {
        return Math.round(distance) + " km";
      } else {
        return "";
      }
    },
    /**
     * Haversine formula to calculate the distance between two points on a sphere based on longitude and latitude.
     */
    haversineDistance([lat1, lon1], [lat2, lon2]): number {
      const toRadian = (angle) => (Math.PI / 180) * angle;
      const distance = (a, b) => (Math.PI / 180) * (a - b);
      const RADIUS_OF_EARTH_IN_KM = 6371;

      const dLat = distance(lat2, lat1);
      const dLon = distance(lon2, lon1);

      lat1 = toRadian(lat1);
      lat2 = toRadian(lat2);

      // Haversine Formula
      const h =
        Math.pow(Math.sin(dLat / 2), 2) +
        Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
      const c = 2 * Math.asin(Math.sqrt(h));

      return RADIUS_OF_EARTH_IN_KM * c;
    },
  },
});
