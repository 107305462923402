
































































import Vue from "vue";
import Post from "@/models/post";

/**
 * Emits @openMap click on button to Map
 * Emits @close close this card
 */
export default Vue.extend({
  name: "PostCard",
  props: {
    post: {
      type: Object as () => Post,
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      columns: [
        { key: "location", label: this.$t("postCard.location") },
        { key: "task", label: this.$t("postCard.task") },
        { key: "contact", label: this.$t("postCard.contact") },
        { key: "organization", label: this.$t("postCard.organization") },
        { key: "target_group", label: this.$t("postCard.target_group") },
        { key: "timing", label: this.$t("postCard.timing") },
        { key: "effort", label: this.$t("postCard.effort") },
        { key: "opportunities", label: this.$t("postCard.opportunities") },
        { key: "prerequisites", label: this.$t("postCard.prerequisites") },
        { key: "language_skills", label: this.$t("postCard.language_skills") },
        {
          key: "source",
          label: this.$t("postCard.source"),
          link: this.$t("postCard.link"),
        },
      ],
    };
  },
  watch: {
    post() {
      if (this.post && this.show) {
        // scroll to top if post change
        this.$el.scrollTop = 0;
      }
    },
  },
  methods: {
    openMap(): void {
      this.$emit("openMap", this.post);
    },
    closePost(): void {
      this.$emit("close");
    },
  },
});
