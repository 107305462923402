











import Vue from "vue";
export default Vue.extend({
  name: "MapButton",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    return {
      open: this.value,
    };
  },
  watch: {
    value() {
      this.open = this.value;
    },
    open() {
      this.$emit("input", this.open);
    },
  },
});
